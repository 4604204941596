import React from 'react'
import { Wrapper } from 'src/components/layout/wrapper'
import imgB1 from 'src/resources/img/RST-PIO-CAN-2.jpg'
import imgB2 from 'src/resources/img/RST-CD.jpg'
import imgB3 from 'src/resources/img/RST-PIO-CAN-DS.jpg'
import imgB4 from 'src/resources/img/RST-Temperaturfuehler-mit-MU101.jpg'

export default () => {
  return (
    <Wrapper>
      <h1>Smarte Filterüberwachung mit vielen Einsatzmöglichkeiten</h1>

      <b>Zuverlässige Überwachungssysteme für Diesel-Partikelfilter (DPF) und andere Anwendungen mit Signalauswertung</b> <br/><br/>

      <span className="font-light">Anwendungen:<br/> Generatoren, Baumaschinen, Mining, Bahn und Gleisbaumaschinen, etc.</span>

      <br/><br/>
      <p>Extrem kompakte - <span className="italic">all in one</span> - Einheit mit Graphikdisplay, kontinuierliche Druck- und Temperaturanzeige sowie Betriebs- und Störmeldungen im Klartext.
        <br/>
        Einfache Installation und Konfiguration mit übersichtlicher, intuitiver Software.
        <br/>
        Schnelle und direkte Auswertung des Datalogger- und Protokollspeichers, ohne komplizierte Datenaufbereitung.
        <br/>
        Entspricht den VERT&reg; Anforderung für Filterüberwachungen.
      </p>

      <div className="flex flex-row justify-between items-center py-10">
        <div><img src={imgB1} alt="RST PIO-CAN 2"/></div>
        <div className="px-10"><img src={imgB2} alt="RST CD"/></div>
        <div className="px-10"><img src={imgB3} alt="RST PIO-CAN-DS"/></div>
        <div><img src={imgB4} alt="RST Temperaturfühler mit MU101"/></div>
      </div>

      <h2>Technische Daten</h2>
      <p>Die Filterüberwachung PIO-CAN ist für nahezu alle Diesel-Partikelfilter einsetzbar. Druck und Temperatur werden mit dem integriertem Datalogger aufgezeichnet. Alle Betriebs- und Störmeldungen werden in einem Alarmspeicher abgelegt.</p>

      <p>In der Standardversion werden der Abgasgegendruck und die Abgastemperatur aufgezeichnet. Optional kann die Drehzahl oder andere Analogsignale mit aufgezeichnet werden.</p>

      <p>Meldungen werden im Graphikdisplay als Klartext angezeigt – keine Fehlercodes! Direkt an der Filterüberwachung kann zwischen fünf voreingestellten Sprachen ausgewählt werden. Weitere Varianten steuern die elektrische Regeneration mit bis zu zwei unabhängigen Regelungen sowie die Diesel-Brennersteuerung.</p>

      <p>Mit der optional erhältlichen Diagnosesoftware PIO-CAN-4.0 können Grenzwerteinstellungen verändert und Daten zu Servicezwecken ausgewertet werden.</p>
      <br/>

      <h2>Technische Spezifikation</h2>
      <div className="flex flex-col">
      	<div className="flex flex-col md:flex-row">
      		<div className="flex-1 font-medium">Spannungsversorgung</div>
      		<div className="flex-1 pb-5 md:pb-0">12-30VDC</div>
      	</div>
      	<div className="flex flex-col md:flex-row">
      		<div className="flex-1 font-medium">Umgebungstemperatur</div>
      		<div className="flex-1 pb-5 md:pb-0">-20°C - +80°C</div>
      	</div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Schutzart</div>
          <div className="flex-1 pb-5 md:pb-0">IP65</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Grafikdisplay</div>
          <div className="flex-1 pb-5 md:pb-0">128 x 64 Pixel, LED-Backlight</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Druckmessbereich</div>
          <div className="flex-1 pb-5 md:pb-0">0-1000mbar</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Temperaturmessbereich</div>
          <div className="flex-1 pb-5 md:pb-0">0-1000°C</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Digitale Eingänge</div>
          <div className="flex-1 pb-5 md:pb-0">max. 12, kurzschlussfest, mit Freilaufdiode für induktive Lasten</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Digitale Ausgänge</div>
          <div className="flex-1 pb-5 md:pb-0">max. 8</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Analogeingänge 0..10V bzw. 0..20mA</div>
          <div className="flex-1 pb-5 md:pb-0">4 (max.12)</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Analogausgänge 0..10V bzw. 0-20mA</div>
          <div className="flex-1 pb-5 md:pb-0">4</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Alarmausgänge</div>
          <div className="flex-1 pb-5 md:pb-0">2, kurzschlussfest</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Datalogger</div>
          <div className="flex-1 pb-5 md:pb-0">ca.1200h bei 60Sek. Intervall</div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">USB-B-Mini Schnittstelle</div>
          <div className="flex-1 pb-5 md:pb-0"></div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">RTC batteriegepuffert ( inkl. Batterie)</div>
          <div className="flex-1 pb-5 md:pb-0"></div>
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 font-medium">Option</div>
          <div className="flex-1 pb-5 md:pb-0">2 unabhängige CAN-Schnittstellen (Fahrzeugbus, NOx-Sensoren, Pumpen etc.)</div>
        </div>
      </div>
    </Wrapper>
  )
}
